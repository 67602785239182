




import { defineComponent } from '@nuxtjs/composition-api';

import { useNostoHome } from '~/diptyqueTheme/composable/nosto/useNostoHome';

export default defineComponent({
  name: 'NostoHome',
  setup() {
    useNostoHome();
  }
});
